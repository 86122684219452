import { Flex } from "antd";

import { KFormUser } from "@components";
import { QuestionStatusOptions } from "@constants/options";
import {
  ECardType,
  EQuestionStatus,
  IQuestionDetails
} from "@types";
import {
  isAfterToday
} from "@utils/date";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { AttachmentsDetail } from "./Attachments";
import { SetReminderSection } from "./SetReminderSection";

export const QuestionDetails = () => {
  const { watch, setValue } = useFormContext<IQuestionDetails>();

  useEffect(() => {
    if (!watch("answer") || watch("endTime")) return;
    setValue("status", EQuestionStatus.ANSWERED);
    setValue("endTime", new Date().toISOString());
  }, [watch("answer")]);

  return (
    <>
      <Flex gap={16} vertical flex={1}>
        <KFormUser.OptionsSelector
          label="Practitioner"
          cardType={ECardType.Practitioners}
        />
        <KFormUser.OptionsSelector
          label="Appointment"
          cardType={ECardType.Appointments}
        />
        <KFormUser.TextArea label="Background Info" name="backgroundInfo" />
        <KFormUser.TextArea
          label="Answer"
          name="answer"
          placeholder="Enter any answers here"
        />
        <KFormUser.Select
          label="Status"
          name="status"
          options={QuestionStatusOptions}
        />
        <Flex gap={16} justify="space-between">
          <KFormUser.DatePicker
            label="Date Asked"
            name="startTime"
            disabledDate={(date) => isAfterToday(date)}
          />
          <KFormUser.EndDatePicker label="Date Answered" name="endTime" />
        </Flex>
        <AttachmentsDetail />
        <SetReminderSection />
      </Flex>
    </>
  );
};
