import { Modal, ModalProps } from "antd";
import { KLgText700, KText } from "components/Text";
import styled from "styled-components";
import { StyledModal } from ".";

const ModalConfirmStyled = styled(Modal)`
  .ant-modal-footer {
    margin-top: 32px;
    display: flex;
    justify-content: end;
    gap: 8px;
  }
  .ant-btn-primary {
    box-shadow: none;
  }
`;

const modalExportProgressProps: ModalProps = {
  okText: "Got it",
  centered: true,
  closeIcon: null,
  cancelButtonProps: { style: { display: "none" } },
  title: <KLgText700>Export to PDF</KLgText700>,
  children: (
    <KText>
      Your export may take a little while to generate on our servers. Keep an
      eye out for an email and a notification here in KinKeeper when it's done.
      You'll receive a link to download it through a secure web page.
    </KText>
  ),
};

export const KModalConfirm = (props: ModalProps) => {
  return (
    <ModalConfirmStyled
      okText="Yes, delete"
      cancelText="No, go back"
      okButtonProps={{ danger: true, size: "large" }}
      cancelButtonProps={{ ghost: true, type: "primary", size: "large" }}
      closeIcon={null}
      centered
      width={414}
      {...props}
    />
  );
};

export const KModalExportProgress = (props: ModalProps) => {
  return (
    <StyledModal
      {...modalExportProgressProps}
      {...props}
    />
  );
};