import { Space } from "antd";
import styled from "styled-components";

import { heightCardItemInfo } from "@constants/size";
import { ESpacing, EBorderRadius, EFontSize, ELineHeight, Color } from "@types";

interface ICardDetailStyledProps {
  $isChecked: boolean;
}

export interface IContainerCardStyled {
  $isActive?: boolean;
  $isDisabled?: boolean;
  $isPrimary?: boolean;
}

export const CardDetailStyled = styled(Space)<ICardDetailStyledProps>`
  width: 100%;
  padding: 0px ${ESpacing.md};
  border-width: 1px;
  border-style: solid;
  border-radius: ${EBorderRadius.lg};
  height: ${heightCardItemInfo}px;
  border-color: ${({ $isChecked }) =>
    $isChecked ? Color.PrimaryBase : Color.Secondary10};
  color: ${Color.Secondary88};
  font-weight: 700;
  font-size: ${EFontSize.lg}px;
  line-height: ${ELineHeight.lg}px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  justify-content: space-between;
  cursor: pointer;
`;

export const ContainerCardStyled = styled.label<IContainerCardStyled>(
  ({ $isActive, $isDisabled, $isPrimary }) => ({
    width: "100%",
    padding: `0px ${ESpacing.md}`,
    border: "1px solid transparent",
    borderRadius: EBorderRadius.lg,
    height: `${heightCardItemInfo}px`,
    borderColor: $isActive ? Color.SuccessBorder : Color.Secondary10,
    color: Color.Secondary88,
    fontWeight: 700,
    fontSize: `${EFontSize.lg}px`,
    lineHeight: `${ELineHeight.lg}px`,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    justifyContent: "space-between",
    display: "flex",
    alignItems: "center",
    cursor: $isDisabled ? "default" : "pointer",
    backgroundColor: $isPrimary ? Color.BgPrimary : Color.White,
    "span, svg": {
      color: $isPrimary ? Color.PrimaryBase : Color.SecondaryBase,
    },
    svg: {
      fill: $isPrimary ? Color.PrimaryBase : Color.SecondaryBase,
      fontSize: EFontSize.xl,
    },
  })
);
