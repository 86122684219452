import { KinKeeperCircleIcon, MeatBallIcon } from "@assets/icons";
import {
  EAvatarSize,
  KAvatarGroups,
  KButton,
  KFormUser,
  KLgText700,
  KMdText,
  KModalConfirm,
  KModalQuickAdd,
  KSemiText700,
  KSmText,
  KSwitch,
  ModalExportPDF,
} from "@components";
import { useKContext } from "@context";
import { zodResolver } from "@hookform/resolvers/zod";
import { useCustomNavigate, useLoggingService } from "@hooks";
import {
  deleteCardDetail,
  deleteProfile,
  getCountTempShareHasCardType,
  updateProfileNotificationsSetting,
  updateRelationshipService,
} from "@services";
import { useProfile, useSwitchProfiles, useUser } from "@store";
import {
  BasicInformation,
  ECardType,
  IAvatar,
  IParams,
  relationshipList,
} from "@types";
import { handleMapText } from "@utils/function-helper";
import { Divider, Flex, Form, Popover, message } from "antd";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { z } from "zod";
import {
  ContainerInfoOptions,
  KBadgeCountStyled,
  StyledButton,
} from "./styles";
import { IKCardMeetBallProps } from "./types";

const editRelationshipSchema = z.object({
  relationship: z.string().nullable().optional(),
});

export const KCardMeatBall: React.FC<IKCardMeetBallProps> = ({
  type = "info",
}) => {
  const { cardId, profileId, cardType } = useParams<IParams>();
  const { navigate } = useCustomNavigate();
  const [showInfoOptions, setShowInfoOptions] = useState(false);
  const [numberOfCustomShare, setNumberOfCustomShare] = useState(0);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const {
    cardTypeSelected,
    title,
    setAmountOfCards,
    amountOfCards,
    forceUpdateList,
  } = useKContext();
  const methods = useForm<BasicInformation>({
    resolver: zodResolver(editRelationshipSchema),
    mode: "onTouched",
  });
  const {
    handleSubmit,
    formState: { isSubmitting, isDirty },
    getValues,
    reset,
  } = methods;

  const { userInfo } = useUser();
  const { profile, setProfile } = useProfile();
  const { setResetSwitch } = useSwitchProfiles();
  const { pushProfileInfoLog, pushInfoTypeLog } = useLoggingService();

  const cardTypeParam = cardTypeSelected || (cardType as ECardType);

  const textMapNormalize = handleMapText(cardTypeParam, false);
  const textMapCapitalizeFirstLetter = handleMapText(cardTypeParam);
  const receiveNotifications = useMemo(() => {
    if (!profile?.acl?.length) {
      return false;
    }
    const findOwner = profile.acl.find((i) => i.accessor === userInfo?._id);
    return findOwner?.isReceiveNotifications ?? false;
  }, [profile, userInfo]);
  const [isReceiveNotifications, setIsReceiveNotifications] =
    useState(receiveNotifications);

  const handleDeleteInfo = async () => {
    if (!profileId || !cardTypeParam || !cardId) return;
    try {
      if (!textMapNormalize) return;
      const { success } = await deleteCardDetail(
        profileId,
        textMapNormalize,
        cardId
      );
      if (success) {
        forceUpdateList();
        const newAmountCards = amountOfCards.map((c) => {
          if (c.cardType === cardTypeParam)
            return { ...c, amount: c.amount - 1 };
          return c;
        });
        setAmountOfCards(newAmountCards);
        const cardDeleted = { _id: cardId, cardType: cardTypeParam };
        pushInfoTypeLog("DELETED", cardDeleted);
        navigate(`/user/${profileId}/${cardTypeParam}`);
        message.success(`Delete ${textMapCapitalizeFirstLetter} successfully!`);
      }
    } catch (error: any) {
      message.error(error?.response?.data?.message);
    } finally {
      setShowConfirmModal(false);
    }
  };

  const handleDeleteProfile = async () => {
    if (!profileId || !profile) return;
    try {
      await deleteProfile(profileId);
      setResetSwitch(`${profileId}-delete`);
      pushProfileInfoLog("DELETED", profile);
      navigate("/user");
      message.success(`Delete Profile successfully!`);
    } catch (error: any) {
      message.error(error?.response?.data?.message);
    } finally {
      setShowConfirmModal(false);
    }
  };

  const handleChange = async (value: boolean) => {
    if (!profileId || !profile) return;
    setDisabled(true);
    try {
      setIsReceiveNotifications(!isReceiveNotifications);
      await updateProfileNotificationsSetting(profileId);
      pushProfileInfoLog("MODIFIED", profile);
    } catch (error) {
      console.error(error);
    } finally {
      setDisabled(false);
    }
  };

  const handleOpenModalRelationship = () => {
    setShowEditModal(true);
    setShowInfoOptions(false);
  };

  const handleOpenModalExport = () => {
    setShowExportModal(true);
    setShowInfoOptions(false);
  };

  const handleEditRelationship = async () => {
    if (!profileId || !userInfo?._id || !profile) return;
    try {
      await updateRelationshipService(
        profileId,
        userInfo._id,
        getValues("relationship")
      );
      const profileUpdated = {
        ...profile,
        relationship: getValues("relationship"),
      };
      pushProfileInfoLog("MODIFIED", profileUpdated);
      setProfile(profileUpdated);
      message.success(`Update Relationship successfully!`);
    } catch (error: any) {
      message.error(error?.response?.data?.message);
    } finally {
      setShowEditModal(false);
    }
  };

  useEffect(() => {
    setIsReceiveNotifications(receiveNotifications);
    return () => {};
  }, [receiveNotifications]);

  useEffect(() => {
    if (showInfoOptions) {
      getNumberOfCustomShare();
    }
  }, [showInfoOptions]);

  useEffect(() => {
    if (!profile?.relationship) return;
    reset(profile);
  }, [profile]);

  const getNumberOfCustomShare = async () => {
    if (!profileId || !cardTypeParam) return;
    try {
      const count = await getCountTempShareHasCardType(
        profileId,
        cardTypeParam
      );
      count && setNumberOfCustomShare(count as unknown as number);
    } catch {}
  };

  const avatarGroups = useMemo(() => {
    return (
      profile?.sharedUser?.map<IAvatar>((p) => {
        const { _id, firstName, lastName, avatar } = p;
        return {
          _id: _id ?? "",
          firstName: firstName ?? "",
          lastName: lastName ?? "",
          avatar: avatar ?? "",
        };
      }) ?? []
    );
  }, [profile?.sharedUser]);
  const contentInfo = () => {
    const textDelete = handleMapText(cardTypeParam);
    return (
      <ContainerInfoOptions>
        <Flex gap={16} vertical>
          <KLgText700>Who can see this?</KLgText700>
          <KAvatarGroups groups={avatarGroups} size={EAvatarSize.medium} />
          <Flex gap={4}>
            <KBadgeCountStyled
              isNeedWidth={false}
              prefix="+"
              counter={numberOfCustomShare}
            />
            <KMdText>Custom Share Links</KMdText>
          </Flex>
          <Flex gap={4} align="center">
            <KinKeeperCircleIcon height={16} width={16} rx={5} />
            <KSmText>Open the App to manage sharing</KSmText>
          </Flex>
          <Divider style={{ margin: 0, marginTop: -4 }} />
          <StyledButton
            onClick={() => {
              setShowConfirmModal(true);
              setShowInfoOptions(false);
            }}
            ghost
            danger
            title={`Delete this ${textDelete}`}
          />
        </Flex>
      </ContainerInfoOptions>
    );
  };

  const contentProfile = useMemo(() => {
    return (
      <ContainerInfoOptions>
        <Flex gap={16} vertical>
          <KButton
            ghost
            onClick={handleOpenModalRelationship}
            type="primary"
            title="Edit Relationship"
          />
          <KButton
            ghost
            onClick={handleOpenModalExport}
            type="primary"
            title="Export PDF"
          />
          <Flex align="center" gap={8} justify="between">
            <KMdText>Receive push notification?</KMdText>
            <KSwitch
              disabled={disabled}
              checked={isReceiveNotifications}
              defaultChecked={isReceiveNotifications}
              onChange={(e) => handleChange(e)}
            />
          </Flex>
          <StyledButton
            onClick={() => {
              setShowConfirmModal(true);
              setShowInfoOptions(false);
            }}
            ghost
            danger
            title="Delete Profile"
          />
        </Flex>
      </ContainerInfoOptions>
    );
  }, [
    isReceiveNotifications,
    setIsReceiveNotifications,
    disabled,
    setDisabled,
    receiveNotifications,
  ]);

  return (
    <>
      <Popover
        placement="bottomRight"
        content={type === "info" ? contentInfo : contentProfile}
        trigger="click"
        onOpenChange={(open) => setShowInfoOptions(open)}
        open={showInfoOptions}
      >
        <Flex justify="center" align="center" style={{ cursor: "pointer" }}>
          <MeatBallIcon />
        </Flex>
      </Popover>
      {type === "info" && (
        <KModalConfirm
          title={`Delete ${textMapCapitalizeFirstLetter}`}
          open={showConfirmModal}
          onOk={handleDeleteInfo}
          onCancel={() => {
            setShowConfirmModal(false);
            setShowInfoOptions(true);
          }}
        >
          <Flex vertical gap={8}>
            <KSemiText700>
              Are you sure you want to delete this {textMapNormalize}
              {title && `, ${title}`}?
            </KSemiText700>
            <KMdText>
              This item will be deleted and cannot be recovered.
            </KMdText>
          </Flex>
        </KModalConfirm>
      )}
      {type === "profile" && (
        <KModalConfirm
          title="Delete Profile"
          open={showConfirmModal}
          onOk={handleDeleteProfile}
          onCancel={() => {
            setShowConfirmModal(false);
            setShowInfoOptions(true);
          }}
        >
          <Flex vertical gap={8}>
            <KSemiText700>
              Are you sure you want to delete this profile?
            </KSemiText700>
            <KMdText>
              This profile will be deleted and cannot be recovered.
            </KMdText>
          </Flex>
        </KModalConfirm>
      )}
      <KModalQuickAdd
        open={showEditModal}
        title="Edit Relationship"
        onCancel={() => setShowEditModal(false)}
      >
        <FormProvider {...methods}>
          <Form onFinish={handleSubmit(handleEditRelationship)}>
            <Flex gap={16} vertical>
              <KLgText700>
                What is{" "}
                {profile?.basicInformation.firstName
                  ? profile?.basicInformation.firstName + `'s`
                  : null}{" "}
                relationship to you?
              </KLgText700>
              <KFormUser.Select
                suffixIcon={null}
                label="Relationship"
                name="relationship"
                options={relationshipList}
              />
              <KButton
                htmlType="submit"
                loading={isSubmitting}
                disabled={!isDirty}
                size="large"
                type="primary"
                title="Save"
              />
            </Flex>
          </Form>
        </FormProvider>
      </KModalQuickAdd>
      <ModalExportPDF
        open={showExportModal}
        onCancel={() => setShowExportModal(false)}
      />
    </>
  );
};
