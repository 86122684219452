import { Color } from "@types";
import { Flex, Modal, ModalProps } from "antd";
import { modalProps } from "config/antd";
import { ReactNode } from "react";
import styled from "styled-components";
import { KButton, KLgText } from "@components";

const BtnCancel = styled(KButton)`
  position: absolute;
  left: 0;
`;

const BtnSave = styled(KButton)`
  position: absolute;
  right: 0;
`;

interface KModalProps extends ModalProps {
  children?: ReactNode;
  title: ReactNode;
}

const stylesModal = {
  content: { padding: 0 },
  body: { padding: 16 },
  header: {
    padding: 10,
    margin: 0,
    border: `1px solid ${Color.Secondary10}`,
  },
};

export const KModalQuickAdd = ({ title, children, onCancel, onOk, open = false,...props }: KModalProps) => {
  return (
    <Modal
      title={
        <Flex align="center" justify="center" style={{ position: "relative" }}>
          {onCancel && <BtnCancel type="link" title="Cancel" onClick={onCancel}/>}
          <KLgText>{title}</KLgText>
          {onOk && <BtnSave size="small" type="primary" title="Save" onClick={onOk}/>}
        </Flex>
      }
      width={414}
      centered
      closeIcon={false}
      footer={null}
      styles={stylesModal}
      onCancel={onCancel}
      open={open}
      {...props}
    > 
      {children}
    </Modal>
  );
};
