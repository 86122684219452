import { apiInstance } from "@apis";
import { IBaseRes } from "@types";

export const getPrincipalExportService = async (exportId: string) => {
  const { data } = await apiInstance.get<IBaseRes<string>>(
    `/v1/export/${exportId}/principal`
  );
  return data;
};

export const requestOTPExport = async (
  exportId: string,
  termsAccepted: boolean,
  ageAccepted: boolean,
  policyAccepted: boolean
) => {
  const { data } = await apiInstance.get(`/v1/export/${exportId}/otp`, {
    params: { termsAccepted, ageAccepted, policyAccepted },
  });
  return data;
};

export const verifyOTPExport = async (exportId: string, otp: string) => {
  const { data } = await apiInstance.post(`/v1/export/${exportId}/otp/${otp}`);
  return data;
};

export const getFileExportedService = async (exportId: string) => {
  const { data } = await apiInstance.get<IBaseRes<string>>(
    `/v1/export/${exportId}`
  );
  return data?.data;
};