import { Checkbox, Flex, SpaceProps } from "antd";
import React, { ReactNode } from "react";

import { KText } from "components/Text";
import {
  CardDetailStyled,
  ContainerCardStyled,
  IContainerCardStyled,
} from "./styles";

export interface ICheckboxCardItemProps extends SpaceProps {
  title: string;
  checked?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

interface ISelectionCardContainer extends IContainerCardStyled {
  children: ReactNode;
}

export const KCheckboxCardItem: React.FC<ICheckboxCardItemProps> = ({
  title,
  checked = false,
  onClick,
}) => (
  <CardDetailStyled $isChecked={checked} onClick={onClick}>
    <KText>{title}</KText>
    <Flex>
      <Checkbox defaultChecked={checked} checked={checked} />
    </Flex>
  </CardDetailStyled>
);

export const SelectionCardContainer = (props: ISelectionCardContainer) => (
  <ContainerCardStyled {...props} />
);
